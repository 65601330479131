import oucy from "@/util/oucyUtil";
import { schemeOrder, ltDeliveryAddress } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Enter",
    data() {
        return {
            pageWidth: oucy.pageWidth,
            checked: 1,
            radio: 1,
            tmpOrder: {},
            list: [],
            ConfirmOrderScheme: {
                billDetail: '', //  发票信息
                billHead: '', //    抬头信息
                billHeadType: 0, //    发票抬头：0个人 1企业
                billType: 0, //  开票类型：0普票 1专票
                deliverAddrId: '', //   收货地址编码
                isCreateBill: !true, //    是否开票
                schemeId: null, //    进货车方案编码
                schemeItemIds: [], //   方案内选中的下单条目编码,格式如：['a','b']
                schemeOrderPayChennel: 0, //   付款方式：0微信支付 1支付宝支付 2货到付款
                uuid: null, //    客户端唯一标识
                skuId: null,
                skuNum: null,
                ticketId: null, //    优惠券编码
            },
            promptly: false,
            skuId: null,
            skuNum: null,
            memberCardUserTicketBasics: [
                /*{
                                "id": "64654",
                                "memberCardUserNumber": "",
                                "memberCardUserTicket4Vip": true,
                                "memberCardUserTicketCategory": 0,
                                "memberCardUserTicketCreatetime": "",
                                "memberCardUserTicketFee": 0,
                                "memberCardUserTicketIcon": "",
                                "memberCardUserTicketLimitFee": 0,
                                "memberCardUserTicketLimittime": "",
                                "memberCardUserTicketName": "",
                                "memberCardUserTicketOrigin": 0,
                                "memberCardUserTicketRemark": "",
                                "memberCardUserTicketScheme": 0,
                                "memberCardUserTicketUsed": true,
                                "memberCardUserTicketUsedOrder": ""
                            }*/
            ],
            memberCardUserTicketFee: 0,
            dialogVisible: false,
            title: '选择优惠券',
            coupon: {},
            ticketId: null,
            dialogVisible: false,
        }
    },
    mounted() {
        this.schemeId = this.$route.query.schemeId
        this.skuId = this.$route.query.skuId
        this.skuNum = this.$route.query.skuNum
        this.getAllLtDeliveryAddress()

        // 立即下单过来的
        if (this.skuId) {
            this.ConfirmOrderScheme.skuId = this.skuId
            this.ConfirmOrderScheme.skuNum = this.skuNum * 1
            this.queryTmpPromptlyOrderScheme(true)
            this.promptly = true
        } else {
            // 进货车过来的
            this.promptly = false
            let tmpOrder = localGet('tmpOrder')
            this.setMessages(tmpOrder.stockEnterprise)
            this.tmpOrder = tmpOrder
            this.memberCardUserTicketBasics = this.tmpOrder.memberCardUserTicketBasics || []
            console.log("进货车过来的订单", this.tmpOrder);

        }

    },
    methods: {
        keywordFiltr(fn) {
            this.$oucy.keywordFiltr(this.ConfirmOrderScheme).then(res => {
                this[fn]()
            }, err => {})
        },
        confirmOrder() {
            if (!this.ConfirmOrderScheme.deliverAddrId) {
                this.alert('还没有收货地址，先新建地址吧！')
                return
            }
            if (this.ConfirmOrderScheme.isCreateBill & !this.ConfirmOrderScheme.billDetail) {
                this.alert('请填写 抬头信息！')
                return
            }
            let schemeItemIds = []
            for (let v of this.tmpOrder.stockEnterprise) {
                for (let vv of v.stockItems) {
                    schemeItemIds.push(vv.itemId)
                }
            }
            if (schemeItemIds && schemeItemIds.length == 0) {
                this.alert('没有商品！')
                return
            }
            if(this.tmpOrder.payFee==0 ||this.tmpOrder.payFee=='0' ||this.tmpOrder.payFee<0){
                this.alert('您所选择的商品中包含"面议"商品，无法生成订单','提示')
               return 
            }
            this.ConfirmOrderScheme.uuid = this.$oucy.getUUID()
            this.ConfirmOrderScheme.schemeId = this.tmpOrder.schemeId
            this.ConfirmOrderScheme.fee = this.tmpOrder.payFee
            this.ConfirmOrderScheme.schemeItemIds = JSON.stringify(schemeItemIds)
            this.ConfirmOrderScheme.messages = JSON.stringify(this.getMessages(this.tmpOrder.stockEnterprise))
            this.ConfirmOrderScheme.payPlatform = 'browser'
            this.ConfirmOrderScheme.promptly = this.promptly
            if (this.memberCardUserTicketBasics && this.memberCardUserTicketBasics.length) {
                this.coupon = {}
                this.dialogVisible = true
            } else {
                this.ajaxConfirmOrderScheme()
            }

        },
        ajaxConfirmOrderScheme() {
            this.ConfirmOrderScheme.type = 'confirmOrderScheme'
            localSet('payObject', this.ConfirmOrderScheme)
            oucy.replace('/payCenter/pay')
            return
            schemeOrder.confirmOrderScheme(this.ConfirmOrderScheme).then(res => {
                res.type = 'reConfirmOrderScheme'
                localSet('payObject', res)
                oucy.replace('/payCenter/pay')
            })
        },
        handleChange(value) {
            console.log(value);
        },
        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
        // 获取地址
        getAllLtDeliveryAddress() {
            ltDeliveryAddress.getAllLtDeliveryAddress({}).then(res => {
                this.list = res || []
                if (this.list.length) {
                    this.ConfirmOrderScheme.deliverAddrId = this.list[0].id
                    for (let v of this.list) {
                        if (v.addressDefault) {
                            this.ConfirmOrderScheme.deliverAddrId = v.id
                        }
                    }
                }
            })
        },
        alert(text, title) {
            this.$alert(text, title || '输入内容不正确', {
                confirmButtonText: '确定',
            });
        },
        openDialogVisible() {
            this.$refs.addAddDialog.resetFields()
            // this.$refs.addAddDialog.openDialogVisible()
        },
        callback() {
            this.getAllLtDeliveryAddress()
        },
        queryTmpPromptlyOrderScheme(no1) {
            schemeOrder.queryTmpPromptlyOrderScheme({ skuNum: this.skuNum * 1, skuId: this.skuId }).then(res => {
                this.setMessages(res.stockEnterprise)
                this.tmpOrder = res
                if (no1) { this.memberCardUserTicketBasics = this.tmpOrder.memberCardUserTicketBasics || [] }
            })
        },
        setMessages(list) {
            for (let v of list) {
                v.messages = null
            }
        },
        getMessages(list) {
            let messages = []
            for (let v of list) {
                messages.push({ enterpriseId: v.enterpriseId, value: v.messages })
            }
            return messages
        },
        changeTicketId() {
            let memberCardUserTicketFee = 0
            for (let v of this.memberCardUserTicketBasics) {
                if (v.id == this.ConfirmOrderScheme.ticketId) {
                    memberCardUserTicketFee = v.memberCardUserTicketFee
                }
            }
            this.memberCardUserTicketFee = memberCardUserTicketFee
        },
        clickCoupon(v) {
            console.log(v)
            this.coupon = v
            this.ConfirmOrderScheme.ticketId = v.id
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
    }
}