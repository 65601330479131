<template>
    <div>
        <div class="container">
            <div class="pageCenter p-10">
                <el-container>
                    <div style="width: 100%">
                        <h2>支付中心</h2>
                        <!-- 有货 -->
                        <div class="content">
                            <div class="contentTop">
                                <div class="contentTopLeft">
                                    <div class="contentTopLeftBts">
                                        <div>选择收货地址</div>
                                    </div>
                                </div>
                                <div class="contentTopRight">
                                    <el-button type="primary" plain @click="openDialogVisible">新增地址</el-button>
                                    <!-- <el-button type="primary" plain @click="$oucy.go('/Address')">管理地址</el-button> -->
                                </div>
                            </div>
                            <div>
                                <div class="contentCenter">
                                    <div v-if="1">
                                        <div class="table site">
                                            <div class="tableHeader m-t-25">
                                                <div class="cell-4">收货人</div>
                                                <div class="cell-3">联系电话</div>
                                                <div class="cell-2">详细收货地址</div>
                                            </div>
                                            <div class="tableBody">
                                                <div class="items">
                                                    <div class="item p-t-10 p-b-10 pointer" v-for="(v,i) of list" :key="i" :class="{'select':ConfirmOrderScheme.deliverAddrId==v.id}" @click="ConfirmOrderScheme.deliverAddrId=v.id">
                                                        <div class="cell-4">
                                                            <!-- <el-radio v-model="radio" :label="i"> -->
                                                            <!-- </el-radio> -->
                                                                {{v.addressUsername}}
                                                        </div>
                                                        <div class="cell-3">{{v.addressPhone}}</div>
                                                        <div class="cell-2">  {{v.chinaDto && v.chinaDto.namePath}}/{{v.addressDetail}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right m-t-15">
                                    <el-pagination layout="prev, pager, next" :total="50" v-if="0">
                                    </el-pagination>
                                </div>
                            </div>
                            <div class="text-left">订单信息</div>
                            <div class="contentCenter">
                                <div v-if="tmpOrder && tmpOrder.stockEnterprise">
                                    <div class="table">
                                        <div class="tableHeader m-t-25">
                                            <!--                    <div class="cell-1">
                                                <el-checkbox v-model="checked">全选</el-checkbox>
                                            </div> -->
                                            <div class="cell-2">商品信息</div>
                                            <div class="cell-3">单价</div>
                                            <div class="cell-4">数量</div>
                                            <div class="cell-5">优惠</div>
                                            <div class="cell-6">小计</div>
                                        </div>
                                        <div class="tableBody">
                                            <div v-for="(v,i) of tmpOrder.stockEnterprise" :key="i">
                                                <div class="itemsHeader f14 m-t-25"><img src="@/assets/images/stockCar/merchant_icon.png" class="m-r-5">商家：<span>{{v.enterpriseName}}</span></div>
                                                <div class="items m-t-10">
                                                    <div class="item p-10" v-for="(vv,ii) of v.stockItems" :key="ii">
                                                        <!--                                                         <div class="cell-1">
                                                            <span class="checkboxLose" v-if="v==1">失效</span>
                                                            <el-checkbox v-model="checked" v-else></el-checkbox>
                                                        </div> -->
                                                        <div class="cell-2">
                                                            <div class="merchandise">
                                                                <div class="merchandiseImg"><img :src="$oucy.ossUrl+vv.itemAvata"></div>
                                                                <div class="merchandiseText p-l-10">
                                                                    <div class="name c-1 14 line-2">{{vv.itemName}}</div>
                                                                    <div class="specification c-2 m-t-15 f12 line-2">规格：{{vv.itemAttrDesc}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cell-3"><span>￥{{vv.itemPrise}}</span></div>
                                                        <div class="cell-4">
                                                            <span class="f12">{{vv.itemCount}}</span>
                                                        </div>
                                                        <div class="cell-5">
                                                            
                                                        </div>
                                                        <div class="cell-6 c-40">
                                                            ￥{{(vv.itemCount*vv.itemPrise).toFixed(2)}}
                                                            <div class="price" v-if=" vv.itemNumberPrise && (vv.itemNumberPrise*1) != NaN && (vv.itemNumberPrise*1)>0"><span class="text">会员价￥{{vv.itemNumberPrise*vv.itemCount}}</span></div>
                                                            <!-- <VIPPrice :data="vv" ></VIPPrice> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="df p-10 df-ai-c">
                                                    <span class="f14 c-1 p-r-10">备注信息</span>
                                                    <el-input type="text" size="mini" style="flex: 1;" v-model="v.messages" placeholder="请输入备注信息" maxlength="45" show-word-limit></el-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="df-jc-s-b df-ai-c f12 m-t-40">
                                    <el-checkbox v-model="ConfirmOrderScheme.isCreateBill"><span class="f14 bold">是否开票</span></el-checkbox>
                                    <div class="m-l-30">
                                        <span class="c-2 m-r-10">发票类型</span>
                                        <span class="c-1">增值税发票</span>
                                    </div>
                                    <div class="m-l-30">
                                        <span class="c-2 m-r-10">发票抬头</span>
                                        <span class="btn" :class="{'select':ConfirmOrderScheme.billHeadType==0}" @click="ConfirmOrderScheme.billHeadType=0">个人
                                            <img src="@/assets/images/stockCar/corner.png" class="corner">
                                        </span>
                                        <span class="btn" :class="{'select':ConfirmOrderScheme.billHeadType==1}" @click="ConfirmOrderScheme.billHeadType=1">企业
                                            <img src="@/assets/images/stockCar/corner.png" class="corner">
                                        </span>
                                    </div>
    <!--                                 <div class="m-l-30">
                                        <span class="c-2 m-r-10">发票信息</span>
                                        <span class="c-1">清单明细</span>
                                    </div> -->
                                    <div class="m-l-30 df-ai-c">
                                        <span class="c-2 m-r-10">抬头信息</span>
                                        <el-input style="width: 200px;" type="textarea" size="mini" v-model="ConfirmOrderScheme.billDetail" placeholder="请输入内容"></el-input>
                                    </div>
                                </div>
                                <div class="text-left m-20 c-1">
                                    <span class="f14 m-r-10 bold">配送方式</span>
                                    <span class="f12 bold-4">城运通物流公司</span>
                                </div>
                                <div class="text-left m-20 c-1" v-if="0">
                                    <span class="f14 m-r-10 bold">使用优惠券</span>
                                    <el-select v-model="ConfirmOrderScheme.ticketId" size="mini" clearable placeholder="请选择使用优惠券" v-if="memberCardUserTicketBasics && memberCardUserTicketBasics.length" @change="changeTicketId">
                                      <el-option
                                        v-for="item in memberCardUserTicketBasics"
                                        :key="item.id"
                                        :label="item.memberCardUserTicketName+' (优惠'+item.memberCardUserTicketFee+'元)'"
                                        :value="item.id">
                                      </el-option>
                                    </el-select>
                                    <span class="f12 bold-4" v-else>无可用优惠券</span>
                                </div>
                            </div>
                            <div class="contentBottom">
                                <div class="contentBottomBody p-l-10">
                                    <div></div>
       
                                    <div class="df df-ai-c">
                                        <div class="c-1 bold f14 m-r-40">
                                            配送费用: <span class="price">￥ 自提<!-- {{tmpOrder.postFee}} --></span>
                                        </div>
                                        <div class="scheme bold f14 m-r-40">
                                            <div>
                                                
                                            <div class="c-40 f14">实付款： <span class="">￥{{(tmpOrder.payFee-memberCardUserTicketFee)>0?(tmpOrder.payFee-memberCardUserTicketFee):0}}</span>
                                            </div>
                                            <div class="price f14" v-if="tmpOrder.beforePrise2 && tmpOrder.beforePrise2.itemNumberPrise">会员价<span>￥{{tmpOrder.beforePrise2.itemNumberPrise}}</span></div>
                                            </div>
                                            <span class="f12 m-l-5" v-if="memberCardUserTicketFee">已选优惠：{{memberCardUserTicketFee}}</span>
                                        </div>
                                        <!-- not 去除色 -->
                                        <div class="setPriceCreateOrder">
                                            <span class="createOrder" @click="keywordFiltr('confirmOrder')">确认支付</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
        <add-add-dialog ref="addAddDialog"  @callback="callback"></add-add-dialog>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="500px" :before-close="handleClose">
            <div>
                <div class="df-jc-s-b">
                    <div>支付金额</div>
                    <div>￥{{(tmpOrder.payFee-memberCardUserTicketFee)>0?(tmpOrder.payFee-memberCardUserTicketFee):0}}</div>
                </div>
                <div class="p-20 coupons">
                    <div v-for="(v,i) of memberCardUserTicketBasics" class="coupon" :key="i">
                        <coupon2 :selectId="coupon.id" @clickCoupon="clickCoupon" :v="v"></coupon2>
                    </div>
                </div>
                <div class="df-jc-s-b">
                    <div>优惠券</div>
                    <div v-if="coupon.memberCardUserTicketFee">-{{coupon.memberCardUserTicketFee}}</div>
                    <div v-else>请选择优惠券</div>
                </div>
                <div class="df-jc-s-b m-t-10">
                    <div>需支付费用</div>
                    <div v-if="coupon.memberCardUserTicketFee">￥{{tmpOrder.payFee-coupon.memberCardUserTicketFee}}</div>
                    <div v-else>￥{{tmpOrder.payFee}}</div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ajaxConfirmOrderScheme(),dialogVisible = false">去支付</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import confirmOrder from './confirmOrder.js'
export default confirmOrder
</script>
<style lang="less" scoped>
@import "./confirmOrder.less";
</style>